import React from 'react'

import {
	BlockTextImageRowSection,
	BlockTextImageRowWrapper,
	ImageWrapper,
	Title,
	Subtitle,
	Text,
	Image,
	BlockImageRow
} from './BlockImageTextRow.styled'

import LinkOrButton from '../../LinkOrButton'

import { getImage } from 'gatsby-plugin-image'

const BlockTextImageRow = data => {
	const content = data.generated ? data.data : data

	return (
		<BlockTextImageRowSection paddingTop={content.paddingTop} paddingBottom={content.paddingBottom}>
			{content.blocks.map((block, i) => {
				return (
					<BlockImageRow key={i}>
						<ImageWrapper>
							<Image image={getImage(block.image.localFile)} alt={block.image.altText} />
						</ImageWrapper>
						<Subtitle>{block.subtitle}</Subtitle>
						<Title>{block.title}</Title>
						<Text>{block.text}</Text>
					</BlockImageRow>
				)
			})}
			<LinkOrButton type={'link'} url={`/extras`} text="Lees meer" />
		</BlockTextImageRowSection>
	)
}

export default BlockTextImageRow
