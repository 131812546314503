import React from 'react'
import { useDriversExperiencePosts } from '../../../hooks/useDriversExperiencePosts'
import LinkOrButton from '../../LinkOrButton'
import Icon from '../../Icon/Icon'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import { getImage } from 'gatsby-plugin-image'

import {
	BlockDriversExperienceSection,
	DriversExperiencePost,
	DriversExperienceImage,
	TopLineWrapper,
	TitleWrapper,
	Brand,
	Model,
	Rating,
	Content
} from './BlockDriversExperience.styled'

// WIP: finish styling
const BlockDriversExperience = data => {
	const driversExperiencePostsData = useDriversExperiencePosts()

	const renderRatingStars = amountOfStars => {
		const stars = []

		for (let i = 0; i < amountOfStars; i++) {
			stars.push(<Icon width="1rem" key={i} height="1rem" color="#FFC107" margin="0 0 0 5px" icon={faStar} />)
		}

		return stars
	}

	return (
		<BlockDriversExperienceSection paddingBottom={data.paddingBottom} paddingTop={data.paddingTop}>
			{driversExperiencePostsData &&
				driversExperiencePostsData.slice(0, data.maximumPostsCount).map(driversExperiencePost => (
					<DriversExperiencePost key={driversExperiencePost.databaseId}>
						<DriversExperienceImage
							image={getImage(driversExperiencePost.postContent.image.localFile)}
							alt={driversExperiencePost.postContent.image.altText}
						/>
						<TopLineWrapper>
							<TitleWrapper>
								<Brand>{driversExperiencePost.postContent.brand}</Brand>
								<Model>{driversExperiencePost.postContent.model}</Model>
							</TitleWrapper>
							<Rating>{renderRatingStars(driversExperiencePost.postContent.rating)}</Rating>
						</TopLineWrapper>

						<Content>{driversExperiencePost.postContent.content.slice(0, 100)}...</Content>
						<LinkOrButton type={'link'} url={`/reviews/${driversExperiencePost.slug}`} text="Lees meer" />
					</DriversExperiencePost>
				))}
		</BlockDriversExperienceSection>
	)
}

export default BlockDriversExperience
