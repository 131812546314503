import React from 'react'
import { useBlogPosts } from '../../../hooks/useBlogPosts'
import LinkOrButton from '../../LinkOrButton'
import Moment from 'moment'

Moment.locale('nl')

import { getImage } from 'gatsby-plugin-image'

import {
	BlockBlogSection,
	BlogPost,
	BlogImage,
	ImageWrapper,
	BlogWrapper,
	ContentWrapper,
	Title,
	Date
} from './BlockBlog.styled'

// WIP: finish styling
const BlockBlog = data => {
	const blogPostsData = useBlogPosts()

	return (
		<BlockBlogSection paddingBottom={data.paddingBottom} paddingTop={data.paddingTop} position={data.imagePosition}>
			<BlogWrapper>
				{blogPostsData &&
					blogPostsData.slice(0, data.maximumPostsCount).map(blogPost => (
						<BlogPost to={blogPost.uri} key={blogPost.databaseId}>
							<ImageWrapper>
								<BlogImage
									image={getImage(blogPost.header.image.localFile)}
									alt={blogPost.header.image.altText}
								></BlogImage>
							</ImageWrapper>
							<ContentWrapper>
								<Date>{Moment(blogPost.date).format('LL')}</Date>
								<Title>{blogPost.postContent.title}</Title>
							</ContentWrapper>
						</BlogPost>
					))}
			</BlogWrapper>
			{data.showReadmoreButton && data.link && (
				<LinkOrButton type={'link'} url={data.link.url} text={data.buttonText} />
			)}
		</BlockBlogSection>
	)
}

export default BlockBlog
