import { useStaticQuery, graphql } from 'gatsby'

export const useBlogPosts = () => {
	const data = useStaticQuery(
		graphql`
			query {
				allWpBlogPost(sort: { fields: [date], order: DESC }, limit: 18) {
					edges {
						node {
							databaseId
							slug
							id
							date
							uri
							postContent {
								title
								text
							}
							header {
								image {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(width: 1200, height: 700)
										}
									}
								}
							}
						}
					}
				}
			}
		`
	)
	return data.allWpBlogPost.edges.map(edge => edge.node)
}
