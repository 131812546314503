import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import UniversalLink from '../../UniversalLink/UniversalLink'

import Section from '../../Section'

const BlockDriversExperienceSection = styled(Section)`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`

const DriversExperiencePost = styled.div`
	/* display: flex; */
	padding: 1rem;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		width: 33.3%;
	}
`

const DriversExperienceImage = styled(GatsbyImage)``

const TopLineWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 20px 0 0 0;
`

const TitleWrapper = styled.div``

const Brand = styled.h3`
	font-size: 1.4rem;
	margin: 0;
`

const Model = styled.h4`
	font-size: 1.1rem;
	margin: 10px 0 15px 0;
`

const Rating = styled.div``

const Content = styled.p``

export {
	BlockDriversExperienceSection,
	DriversExperiencePost,
	DriversExperienceImage,
	TopLineWrapper,
	TitleWrapper,
	Brand,
	Model,
	Rating,
	Content
}
