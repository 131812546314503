import { useStaticQuery, graphql } from 'gatsby'

export const useDriversExperiencePosts = () => {
	const data = useStaticQuery(
		graphql`
			query {
				allWpDriversExperience(sort: { fields: [date], order: DESC }, limit: 18) {
					edges {
						node {
							databaseId
							slug
							id
							date
							uri
							postContent {
								brand
								model
								authorName
								rating
								content
								image {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(width: 700, height: 450)
										}
									}
								}
							}
						}
					}
				}
			}
		`
	)
	return data.allWpDriversExperience.edges.map(edge => edge.node)
}
