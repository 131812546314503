import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import Section from '../../Section'

const BlockTextImageRowSection = styled(Section)`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding-top: ${props => props.paddingTop}px;
	padding-bottom: ${props => props.paddingBottom}px;
`

const BlockTextImageRowWrapper = styled.div``

const BlockImageRow = styled.div`
	padding: 1rem;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		width: 33.3%;
	}
`

const Title = styled.h3`
	font-size: 1.2rem;
	margin: 10px 0 15px 0;
`

const Subtitle = styled.h4`
	font-size: 14px;
	margin: 30px 0 0 0;
	text-transform: uppercase;
	color: #b4b4b4;
`

const Text = styled.span`
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	white-space: pre-wrap;
`

const ImageWrapper = styled.div`
	overflow: hidden;
	height: 250px;
`

const Image = styled(GatsbyImage)`
	height: 100%;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		height: 250px;
	}
`

export { BlockTextImageRowSection, Title, Subtitle, Text, BlockImageRow, Image, BlockTextImageRowWrapper, ImageWrapper }
