import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import UniversalLink from '../../UniversalLink/UniversalLink'

import Section from '../../Section'

const BlockBlogSection = styled(Section)`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`

const BlogPost = styled(UniversalLink)`
	/* width: 33.3%; */
	padding: 1rem;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		width: 50%;
	}
`

const BlogWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
`

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	/* padding: 1rem 0; */
`

const ImageWrapper = styled.div`
	overflow: hidden;
`

const BlogImage = styled(GatsbyImage)`
	transition: transform 0.5s ease;

	${BlogPost}:hover & {
		transform: scale(1.2);
	}
`

const Title = styled.h3`
	font-size: 1rem;
	/* text-align: center; */
	line-height: 1.8rem;
	margin: 10px 0;

	${BlogPost}:hover & {
		text-decoration: underline;
	}
`

const Date = styled.span`
	font-size: 0.8rem;
	align-self: flex-end;
	color: #8e8e8e;
`

export { BlockBlogSection, BlogPost, BlogImage, ImageWrapper, BlogWrapper, Title, Date, ContentWrapper }
